import type { ExplicitAny } from "~/@types/generics"

export const notFoundData: Record<string, ExplicitAny[]> = {
  fr: [
    {
      _uid: "341c7237-9c57-4686-b924-e4d0a6f94237",
      layout: "center",
      content: [
        {
          cta: [
            {
              _uid: "223b6e6b-9dd6-4d85-bb21-05874f4b57df",
              link: {
                id: "ba913b6b-4784-4110-a3db-b76d664b70a9",
                url: "",
                linktype: "story",
                fieldtype: "multilink",
                cached_url: "pages/",
                story: {
                  name: "Home",
                  id: 558661661,
                  uuid: "ba913b6b-4784-4110-a3db-b76d664b70a9",
                  slug: "pages",
                  url: "pages/",
                  full_slug: "pages/",
                  _stopResolving: true,
                },
              },
              color: "grey-bg-page",
              label: "Je retourne à l'accueil ",
              component: "cta",
              obfuscate: false,
              background: "primary",
              display_icon: false,
              product_with_variant: {
                _uid: "70818302-c1d0-44d0-adf8-cf9fc1d2139a",
                data: {
                  product: null,
                  variant: null,
                },
                title: "Unlikely's Storyblok Shopify Product integration field with variant",
                plugin: "integration-shopify-product-with-variant",
              },
            },
          ],
          _uid: "efacf4cf-6b1f-4970-89ee-4ac4576a8c7b",
          asset: [
            {
              _uid: "4c5b5474-fb1d-46a9-b64f-33c918b614e3",
              image: {
                id: 17599448,
                alt: "draps étendus",
                name: "",
                focus: "",
                title: "",
                source: "",
                filename: "https://a.storyblok.com/f/307472/2048x1365/ecd9f3cd0e/drap_etendu.jpeg",
                copyright: "",
                fieldtype: "asset",
                meta_data: {},
                is_external_url: false,
              },
              component: "asset",
              mobile_image: [
                {
                  _uid: "8b01c9c6-aa5f-4f03-916e-487d35e63851",
                  image: {
                    id: 17599448,
                    alt: "draps étendus",
                    name: "",
                    focus: "",
                    title: "",
                    source: "",
                    filename: "https://a.storyblok.com/f/307472/2048x1365/ecd9f3cd0e/drap_etendu.jpeg",
                    copyright: "",
                    fieldtype: "asset",
                    meta_data: {},
                    is_external_url: false,
                  },
                  component: "optional_image",
                },
              ],
            },
          ],
          title: {
            type: "doc",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    text: "Vous voilà dans de beaux draps ! ",
                    type: "text",
                  },
                ],
              },
            ],
          },
          subtitle: {
            type: "doc",
            content: [
              {
                type: "paragraph",
              },
            ],
          },
          suptitle: {
            type: "doc",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    text: "Erreur 404 : ",
                    type: "text",
                  },
                ],
              },
            ],
          },
          component: "home_hero_card_shared__content",
          text_color: "",
        },
      ],
      component: "hero_main",
    },
  ],
  nl: [
    {
      _uid: "341c7237-9c57-4686-b924-e4d0a6f94237",
      layout: "center",
      content: [
        {
          cta: [
            {
              _uid: "223b6e6b-9dd6-4d85-bb21-05874f4b57df",
              link: {
                id: "ba913b6b-4784-4110-a3db-b76d664b70a9",
                url: "",
                linktype: "story",
                fieldtype: "multilink",
                cached_url: "/nl/pages/",
                prep: true,
                story: {
                  name: "Home",
                  id: 558661661,
                  uuid: "ba913b6b-4784-4110-a3db-b76d664b70a9",
                  slug: "pages",
                  url: "pages/",
                  full_slug: "nl/pages/",
                  _stopResolving: true,
                },
              },
              color: "grey-bg-page",
              label: "Startpagina",
              component: "cta",
              obfuscate: false,
              background: "primary",
              display_icon: false,
              product_with_variant: {
                _uid: "70818302-c1d0-44d0-adf8-cf9fc1d2139a",
                data: {
                  product: null,
                  variant: null,
                },
                title: "Unlikely's Storyblok Shopify Product integration field with variant",
                plugin: "integration-shopify-product-with-variant",
              },
            },
          ],
          _uid: "efacf4cf-6b1f-4970-89ee-4ac4576a8c7b",
          asset: [
            {
              _uid: "4c5b5474-fb1d-46a9-b64f-33c918b614e3",
              image: {
                id: 17599448,
                alt: "draps étendus",
                name: "",
                focus: "",
                title: "",
                source: "",
                filename: "https://a.storyblok.com/f/307472/2048x1365/ecd9f3cd0e/drap_etendu.jpeg",
                copyright: "",
                fieldtype: "asset",
                meta_data: {},
                is_external_url: false,
              },
              component: "asset",
              mobile_image: [
                {
                  _uid: "8b01c9c6-aa5f-4f03-916e-487d35e63851",
                  image: {
                    id: 17599448,
                    alt: "draps étendus",
                    name: "",
                    focus: "",
                    title: "",
                    source: "",
                    filename: "https://a.storyblok.com/f/307472/2048x1365/ecd9f3cd0e/drap_etendu.jpeg",
                    copyright: "",
                    fieldtype: "asset",
                    meta_data: {},
                    is_external_url: false,
                  },
                  component: "optional_image",
                },
              ],
            },
          ],
          title: {
            type: "doc",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    text: "404-fout",
                    type: "text",
                    marks: [
                      {
                        type: "textStyle",
                        attrs: {
                          color: "rgb(17, 24, 39)",
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          subtitle: {
            type: "doc",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    text: "Het lijkt erop dat deze pagina in de was is verdwenen. Neem een kijkje in onze collectie beddengoed om iets comfortabels te vinden!",
                    type: "text",
                  },
                ],
              },
            ],
          },
          suptitle: {
            type: "doc",
            content: [
              {
                type: "paragraph",
              },
            ],
          },
          component: "home_hero_card_shared__content",
          text_color: "",
        },
      ],
      component: "hero_main",
    },
  ],
  de: [
    {
      _uid: "341c7237-9c57-4686-b924-e4d0a6f94237",
      layout: "center",
      content: [
        {
          cta: [
            {
              _uid: "223b6e6b-9dd6-4d85-bb21-05874f4b57df",
              link: {
                id: "ba913b6b-4784-4110-a3db-b76d664b70a9",
                url: "",
                linktype: "story",
                fieldtype: "multilink",
                cached_url: "/de/pages/",
                prep: true,
                story: {
                  name: "Home",
                  id: 558661661,
                  uuid: "ba913b6b-4784-4110-a3db-b76d664b70a9",
                  slug: "pages",
                  url: "pages/",
                  full_slug: "de/pages/",
                  _stopResolving: true,
                },
              },
              color: "grey-bg-page",
              label: "Ich gehe zurück zum Empfang",
              component: "cta",
              obfuscate: false,
              background: "primary",
              display_icon: false,
              product_with_variant: {
                _uid: "70818302-c1d0-44d0-adf8-cf9fc1d2139a",
                data: {
                  product: null,
                  variant: null,
                },
                title: "Unlikely's Storyblok Shopify Product integration field with variant",
                plugin: "integration-shopify-product-with-variant",
              },
            },
          ],
          _uid: "efacf4cf-6b1f-4970-89ee-4ac4576a8c7b",
          asset: [
            {
              _uid: "4c5b5474-fb1d-46a9-b64f-33c918b614e3",
              image: {
                id: 17599448,
                alt: "draps étendus",
                name: "",
                focus: "",
                title: "",
                source: "",
                filename: "https://a.storyblok.com/f/307472/2048x1365/ecd9f3cd0e/drap_etendu.jpeg",
                copyright: "",
                fieldtype: "asset",
                meta_data: {},
                is_external_url: false,
              },
              component: "asset",
              mobile_image: [
                {
                  _uid: "8b01c9c6-aa5f-4f03-916e-487d35e63851",
                  image: {
                    id: 17599448,
                    alt: "draps étendus",
                    name: "",
                    focus: "",
                    title: "",
                    source: "",
                    filename: "https://a.storyblok.com/f/307472/2048x1365/ecd9f3cd0e/drap_etendu.jpeg",
                    copyright: "",
                    fieldtype: "asset",
                    meta_data: {},
                    is_external_url: false,
                  },
                  component: "optional_image",
                },
              ],
            },
          ],
          title: {
            type: "doc",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    text: "Sie sind in Schwierigkeiten",
                    type: "text",
                  },
                ],
              },
            ],
          },
          subtitle: {
            type: "doc",
            content: [
              {
                type: "paragraph",
              },
            ],
          },
          suptitle: {
            type: "doc",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    text: "404-Fehler",
                    type: "text",
                  },
                ],
              },
            ],
          },
          component: "home_hero_card_shared__content",
          text_color: "",
        },
      ],
      component: "hero_main",
    },
  ],
  it: [
    {
      _uid: "341c7237-9c57-4686-b924-e4d0a6f94237",
      layout: "center",
      content: [
        {
          cta: [
            {
              _uid: "223b6e6b-9dd6-4d85-bb21-05874f4b57df",
              link: {
                id: "ba913b6b-4784-4110-a3db-b76d664b70a9",
                url: "",
                linktype: "story",
                fieldtype: "multilink",
                cached_url: "/it/pages/",
                prep: true,
                story: {
                  name: "Home",
                  id: 558661661,
                  uuid: "ba913b6b-4784-4110-a3db-b76d664b70a9",
                  slug: "pages",
                  url: "pages/",
                  full_slug: "it/pages/",
                  _stopResolving: true,
                },
              },
              color: "grey-bg-page",
              label: "Torno alla reception ",
              component: "cta",
              obfuscate: false,
              background: "primary",
              display_icon: false,
              product_with_variant: {
                _uid: "70818302-c1d0-44d0-adf8-cf9fc1d2139a",
                data: {
                  product: null,
                  variant: null,
                },
                title: "Unlikely's Storyblok Shopify Product integration field with variant",
                plugin: "integration-shopify-product-with-variant",
              },
            },
          ],
          _uid: "efacf4cf-6b1f-4970-89ee-4ac4576a8c7b",
          asset: [
            {
              _uid: "4c5b5474-fb1d-46a9-b64f-33c918b614e3",
              image: {
                id: 17599448,
                alt: "draps étendus",
                name: "",
                focus: "",
                title: "",
                source: "",
                filename: "https://a.storyblok.com/f/307472/2048x1365/ecd9f3cd0e/drap_etendu.jpeg",
                copyright: "",
                fieldtype: "asset",
                meta_data: {},
                is_external_url: false,
              },
              component: "asset",
              mobile_image: [
                {
                  _uid: "8b01c9c6-aa5f-4f03-916e-487d35e63851",
                  image: {
                    id: 17599448,
                    alt: "draps étendus",
                    name: "",
                    focus: "",
                    title: "",
                    source: "",
                    filename: "https://a.storyblok.com/f/307472/2048x1365/ecd9f3cd0e/drap_etendu.jpeg",
                    copyright: "",
                    fieldtype: "asset",
                    meta_data: {},
                    is_external_url: false,
                  },
                  component: "optional_image",
                },
              ],
            },
          ],
          title: {
            type: "doc",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    text: "Sei in grossi guai !",
                    type: "text",
                  },
                ],
              },
            ],
          },
          subtitle: {
            type: "doc",
            content: [
              {
                type: "paragraph",
              },
            ],
          },
          suptitle: {
            type: "doc",
            content: [
              {
                type: "paragraph",
                content: [
                  {
                    text: "errore 404",
                    type: "text",
                  },
                ],
              },
            ],
          },
          component: "home_hero_card_shared__content",
          text_color: "",
        },
      ],
      component: "hero_main",
    },
  ],
}
